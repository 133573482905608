@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("./data/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"),
    url("./data/fonts/Poppins-Light.ttf") format("truetype");
  font-weight: light;
}
@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
    url("./data/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: medium;
}
@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("./data/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"),
    url("./data/fonts/Poppins-SemiBold.ttf") format("truetype");
}
[data-theme="dark"] {
  --foreground-color: #000000;
  --background-color: #111;

  --white-point-8: rgba(255, 255, 255, 0.8);
  --white-point-5: rgba(255, 255, 255, 0.5);
  --dark-32: rgba(32, 32, 32, 0.8);

  --letter-color: rgba(220, 220, 220, 1);
  --primary-color: rgb(38, 5, 5);
  --primary-color: rgb(13, 109, 194);
  --full-gradient: linear-gradient(
    to bottom,
    var(--primary-color),
    var(--primary-color)
  );
  --border-color: var(--primary-color);
  --shadow-color: rgba(255, 255, 255, 0.25);
  --container-background: rgba(40, 40, 40, 1);
  --subcontainer-background: rgba(255, 255, 255, 0.06);
  --main-background: linear-gradient(
    to bottom,
    rgba(59, 43, 122, 1),
    rgba(36, 22, 43, 1)
  );
  --subcontainer-message: var(--primary-color);
  --message-user-background: rgba(120, 120, 130, 1);
  --message-bot-background: var(--primary-color);
  --message-writing: rgba(50, 50, 50, 1);
  --chat-input-background: rgba(50, 50, 50, 1);

  --opt-selected: var(--primary-color);
  --opt-deselected: rgba(255, 255, 255, 0.4);

  --notifications-background: rgba(32, 32, 32, 0.96);
  --notification-title-background: var(--primary-color);
  --notification-title: var(--dark-32);
  --button-background: var(--full-gradient);
  --scrollbar: var(--primary-color);
  --popup-border: var(--primary-color);
  --box-input-background: var(--white-point-5);
  --plus-one-step: var(--primary-color);
}

:root {
  --background-color: #eee;

  --white-point-8: rgba(255, 255, 255, 0.8);
  --white-point-5: rgba(255, 255, 255, 0.5);
  --dark-32: rgba(32, 32, 32, 0.8);

  /* --primary-color: rgba(210, 122, 253, 1);
  --primary-color: rgba(110, 156, 255,1); */

  --primary-color: rgb(13, 109, 194);
  --letter-color: #444;
  --border-color: rgba(255, 255, 255, 0.8);
  --subcontainer-background: var(--white-point-8);
  --container-background: rgb(255, 255, 255);

  --full-gradient: rgba(57, 169, 245, 0.6);
  --main-background: linear-gradient(
    to bottom,
    rgb(181, 53, 250),
    rgb(102, 97, 254)
  );
  --message-user-background: rgba(100, 100, 120, 1);
  --message-bot-background: var(--primary-color);
  --message-writing: var(--white-point-8);
  --chat-input-background: white;

  --opt-selected: var(--primary-color);
  --opt-deselected: #444;

  --notifications-background: rgba(255, 255, 255, 0.5);
  --notification-message-container: var(--dark-32);
  --notification-message-writing: var(--white-point-8);
  /* --notification-title-background: var(--primary-color); */
  --notification-title: var(--dark-32);
  --button-background: var(--primary-color);
  --scrollbar: #444;
  --popup-border: var(--white-point-8);
  --box-input-background: rgba(255, 255, 255, 0.4);
  --plus-one-step: rgba(57, 169, 245, 0.4);
}

* {
  margin: 0;
  padding: 0;
  font-family: "PoppinsRegular", sans-serifs;
}
*,
::before,
::after {
  box-sizing: border-box;
  font-family: "Charlie ", sans-serif;
}

body {
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}
:root::-webkit-scrollbar {
  display: none;
  background-color: var(--scrollbar);
}

.app {
  min-height: 100vh;
  max-width: 100vw;
  color : var(--letter-color);
  background-color: var(--background-color);
  background-image: url('./data/images/wave.svg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  width: 95%;
  animation-name: appear;
}

.stats-block {
  justify-content: flex-end;
  gap: 1.5em;
}

.standard-container {
  transition: 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70vh;
  width: 95%;
  justify-self: center;
  margin: 1em;
  background-color: var(--container-background);
  /* border: 1px solid var(--border-color); */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 14px;
}

.stats-container {
  height: 45%;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
  padding: 0.5em;
}

h1 {
  font-size: large;
  padding: 0.5em;
  color: var(--letter-color);
}

.form-input-title {
  color: var(--letter-color);
  font-size: medium;
  font-weight: 400;
}
.new-project-description {
  font-size: 0.1em;
}
.standard-container-button.small {
  font-size: small;
}
.standard-container-button.medium {
  font-size: medium;
}
.standard-container-input {
  font-size: small;
}

.standard-container-button {
  position: absolute;
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  border-bottom: none;
  border-right: none;
  font-size: small;
  color: #fff;
  font-weight: 500;
  font-size: medium;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: var(--primary-color);
  box-shadow: -5px -4px 5px 0px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  bottom: 0;
  z-index: 1;
}
@media screen and (width > 700px) {
  .form-input-title {
    font-size: large;
  }
  h1 {
    font-size: x-large;
    padding: 0.5em;
  }
  .standard-container-button {
    font-size: medium;
  }
  .standard-container-input {
    font-size: medium;
  }
}
@media screen and (width > 1400px) {
  .standard-container-input {
    font-size: large;
  }
  h1 {
    font-size: x-large;
    padding: 0.5em;
  }
  body {
    overflow: scroll;
  }
  .statc-block {
    justify-content: space-between;
  }
  .block {
    max-width: 700px;
  }
}
@keyframes appear {
  0% {
    opacity: 0;
    scale: 0.8;
  }
  100% {
    opacity: 1;
    scale: 1;
  }
}

.standard-container-title {
  top: 0;
  width: 100%;
  text-align: center;
  color: white;
  /* border-bottom: 1px solid var(--border-color); */
  /* background-image: var(--full-gradient); */
  background-color: transparent;

  /* background-color: var(--xxx); */
  border-radius: 5px 5px 0 0;
  display: flex;
  min-height: 8%;
}

.standard-container-title > h1 {
  color: var(--letter-color);
}

.standard-container-title.hidden {
  border-radius: 10px 10px 10px 10px;
}

.standard-container-title h1 {
  width: 80%;
  font-family: "PoppinsLight";
}

.standard-container .standard-container-button.small {
  padding: 1% 3% 1% 3%;
  font-size: 1.5em;
}

.standard-container.hiddable.hidden {
  height: 0;
}
.standard-container-button.notification {
  padding: 0.5em 1em 0.5em 1em;
}
.standard-container-button.medium {
  min-width: fit-content;
  padding: 1em 2em 1em 2em;
  height: 1em;
}

.standard-container-button.large {
  width: 30%;
}

.standard-container-button.left {
  left: 0;
  border-radius: 0 10px 0 8px;
  box-shadow: 0px 0px 5px 0px var(--border-color);
  border-left: none;
  /* border-right: 1px solid white; */
}

.standard-container-button.right {
  border-radius: 10px 0 8px 0;
  box-shadow: 0px 0px 5px 0px var(--border-color);
  right: 0;
}
.standard-container-button:hover {
  filter: brightness(1.2);
}
input {
  cursor: pointer;
}

.wave {
  position: absolute;
  bottom: 0;
  width: 100%;
  opacity: 0.2;
  z-index: 0;
  pointer-events: none;
}
