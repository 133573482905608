.add-task-container {
  height: 100%;
  width: 100%;
  margin-bottom: 5em;
  overflow-y: scroll;
}

.add-task-container::-webkit-scrollbar {
  width: 7px;
}

.add-task-container::-webkit-scrollbar-track {
  background: transparent;
}

.add-task-container::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
  border-radius: 10px; /* Roundness of the scroll thumb */
  padding-right: 0.2em;
}
.new-task-input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.add-task-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 0 1em 0;
  gap: 0.5em;
}

.plus-step {
  padding: 0.5em 1em 0.5em 1em;
  border-radius: 10px;
  background-color: var(--primary-color);
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: large;
}

.plus-step:hover {
  filter: brightness(1.1);
}
.standard-container-input.step {
  width: 90%;

  margin-top: 0em;
}
.standard-container-input.title.step {
  height: 40%;
}

.standard-container-input.title.onestep {
  height: 20%;
}

.standard-container-input.description {
  height: 100px;
}
.one-new-step-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.delete-new-step {
  height: 2em;
}
.steps-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.one-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 1.5em;
  width: 90%;
  margin: 1em;
  border-radius: 14px;
  position: relative;
  background: var(--subcontainer-background);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

.delete-step {
  position: absolute;
  top: 8%;
  left: 87%;
  cursor: pointer;
  font-weight: bold;
}

.delete-step img {
  width: 80%;
}
.delete-step img:hover {
  transform: scale(1.1);
}

.form-title {
  margin: 0.5em;
  font-size: x-large;
  text-align: left;
}

.task-detail-container {
  display: flex;
  justify-content: left;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  max-height: 90%;
  overflow-y: scroll;
  flex-direction: column;
}

.task-detail-container::-webkit-scrollbar {
  width: 7px;
}

.task-detail-container::-webkit-scrollbar-track {
  background: transparent;
}

.task-detail-container::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
  border-radius: 10px; /* Roundness of the scroll thumb */
  padding-right: 0.2em;
}

.links-container li {
  margin-top: 1em;
}

.links-container {
  padding-bottom: 3em;
}

.link {
  text-decoration: underline;
  color: var(--letter-color);
  margin-top: 1em;
}

.estimated-time-box {
  height: 100%;
  width: 25%;
}

.estimated-time-container {
  height: 20%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: x-large;
}

.standard-container-title {
  word-break: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
}

.standard-container-title-icon {
  cursor: pointer;
  position: absolute;
  width: 2em;
  height: 2em;
  right: 0;
  margin-right: 1em;
}

.standard-container-title-icon:hover {
  transform: scale(1.1);
}

.task-description {
  margin-top: 2em;
  margin-bottom: 2em;
  word-break: break-word;
}

.task-state-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.5em 0 1.5em;
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.task-state-container.notstarted {
  background-color: rgba(85, 171, 232, 0.747);
}
.task-state-container.inprogress {
  background-color: rgba(255, 191, 54, 0.747);
}
.task-state-container.finished {
  background-color: rgba(141, 232, 85, 0.747);
}

.task-state-container label {
  font-weight: bold;
}

.step {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1em;
  border-radius: 24px;
  padding: 1em;
  width: 100%;
  word-break: break-word;
  justify-content: space-between;
  gap: 0.7em;
  color: var(--letter-color);
  background-color: var(--background-color);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

.step-data-container {
  display: flex;
  flex-direction: column;
  font-size: small;
  width: 100%;
  gap: 1em;
}

input[type="checkbox"] {
  accent-color: var(--primary-color);
  margin-left: 1em;
}

.step-number {
  height: 2em;
  width: 2em;
  min-width: 2em;
  display: flex;
  align-self: flex-start;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin: 0.5em;
  background-color: var(--primary-color);
  color: white;
}

.step-container {
  display: flex;
  align-items: center;
}

.standard-container-title.task-name h1 {
  font-size: medium;
}

.task-detail-container.chatbot {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
}

.getHelp-container {
  display: flex;
  align-self: flex-end;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  padding: 0.354em;
  border-radius: 10px;
  gap: 0.1em;
  cursor: pointer;
  background-color: var(--primary-color);
}

.getHelp-container:hover {
  filter: brightness(1.1);
}

.getHelp-text {
  font-size: x-small;
  white-space: nowrap;
  cursor: pointer;
  color: white;
}

.getHelp-text.header {
}

.getHelp-container.header {
  gap: 0.1em;
}

.step-description {
  text-align: left;
  font-size: medium;
}

.step-header-container {
  word-break: break-word;
  width: 100%;
  display: flex;
  justify-content: left;
  gap: 1em;
  align-items: center;
}

.delete-new-step.dark,
.standard-container-title-icon {
  filter: invert();
  width: 1.5em;
  height: 1.5em;
}

.delete-new-step:hover {
  scale: 1.1;
  cursor: pointer;
  animation: 0.2 all;
}
.no-task-selected {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
