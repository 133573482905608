.stats-one-stat {
  max-height: 20%;
  width: 90%;

  border: 1px solid #ffffff;
  padding: 0.5em;
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: small;
}

@media screen and (width > 500px) {
  .stats-one-stat {
    font-size: medium;
  }
  .stats-one-stat {
    padding: 0.5em;
  }
}
