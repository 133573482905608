.project-list {
  display: flex;
  gap: 0.5em;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 100%;
  padding-top: 2%;
  overflow: auto;
  border-radius: 24px;
  padding-bottom: 10%;
  overflow-x: auto;
  z-index: 1;
}

.project-list::-webkit-scrollbar {
  width: 7px;
}

.project-list::-webkit-scrollbar-track {
  background: transparent;
}

/* Style the thumb of the scrollbar */
.project-list::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
  border-radius: 10px; /* Roundness of the scroll thumb */
  padding-right: 0.2em;
}

.projectList-one-project a {
  text-decoration: none;
  color: var(--letter-color);
}

.link-to-project {
  text-decoration: none;
  padding-left: 1em;
  color: var(--letter-color);
  font-size: medium;
}
