.header-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  animation-name: slide-in-out;
  animation-duration: 1s;
  padding: 1em 2em 1em 2em;
  gap: 1em;
}

@media all and (width > 1000px) {
  .header-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.header-right-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

/* .notification-container {
  background: rgba(36, 36, 36, 0.9);
  position: fixed;
  top: 7em;
  right: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  border-radius: 24px;
  min-width: 200px;
  min-height: 80px;
  max-width: 350px;
}
 */
.notification-container {
  transition: 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  position: absolute;
  background-color: rgba(20, 20, 20, 0.4);
  color: var(--letter-color);
  top: 10em;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  z-index: 5;
  border-radius: 24px;
  min-height: fit-content;
  width: 95%;
  max-height: 80vh;
  overflow-y: scroll;
  max-width: 380px;
  backdrop-filter: blur(8px);
}

.notification-container.light {
  background-color: rgba(220, 220, 220, 0.4);
}
.notification-container::-webkit-scrollbar {
  display: none;
}
.noInvites {
  margin: 4%;
  font-size: x-large;
  color: var(--letter-color);
}
@media screen and (width > 410px) {
  .notification-container {
    /* left: calc(100% - 410px); */
  }
}

.circles-notification-container {
  display: flex;
  flex-direction: column;
}
.circles-container {
  display: flex;
  gap: 1em;
  margin-left: 0.5em;
  height: 2em;
  align-items: center;
}
.notification-standard-container {
  z-index: 100;
  height: fit-content;
  max-width: 350px;
  /* border-bottom-right-radius: 10px; */
}
.notification-container-title {
  top: 0;
  width: 100%;
  text-align: center;
  transition: all 1s;
  background-color: var(--primary-color);
  border-radius: 10px 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 8%;
}
.notification-container-title > h1 {
  color: var(--letter-color);
  font-size: large;
  font-weight: 500;
}

.notification-message-container {
  padding: 1em 2em 3em 2em;

  color: var(--letter-color);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.circle-header {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.circle-header img {
}
.circle-header.notification {
  background-color: var(--primary-color);
  height: 2em;
  width: 2em;
}
.red {
  background-color: rgba(255, 0, 0, 0.625) !important;
}

.circles-container img {
  height: 1.5em;
  cursor: pointer;
}

.user-name-container {
  display: flex;
  align-items: center;
  min-height: 2em;
  padding-right: 1em;
  padding-left: 1em;
  cursor: pointer;
  border: 2px solid var(--primary-color);
  border-radius: 10px;
}

.user-name-container img {
  height: 1.2em;
  margin-left: 20px;
}

.user-name {
  font-size: 15px;
  color: var(--letter-color);
}

@media screen and (width > 700px) {
  .user-name {
    font-size: 20px;
  }
}

.header-logo-image {
  cursor: pointer;
  font-size: 2em;
  font-weight: 600;
}

.header-back-image {
  margin-right: 0.5em;
  cursor: pointer;
}
.back-img {
  height: 1.2em;
}

.circle-header.notification.jump-animation {
  animation: jump 0.5s;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-6px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}

.header-icon.dark {
  filter: invert();
}

.header-icon.sun {
  filter: invert();
}

.header-logo {
  height: 5em;
}

.header-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (width > 1000px) {
  .notification-container {
    top: 6em;
    right: 1%;
  }
  .header-logo-image {
    font-size: 2.5em;
  }
}
