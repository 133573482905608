.no-task-yet {
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.todo-loading-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noHover {
  pointer-events: none;
}
