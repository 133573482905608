.dashboard-container {
  min-height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.dashboard-loading-container {
  min-height: 70vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 8em;
  justify-content: center;
  gap: 10em;
  margin-top: 8em;
}
.dashboard-loading-container .loading-text {
  font-size: 2em;
  font-weight: 100;
  color: var(--letter-color);
  text-align: center;
}

.loading-secondary-text {
  font-size: 1em;
  margin-top: 0;
  text-align: center;
  font-weight: 100;
}

.loading-text-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  justify-content: center;
  padding: 2em;
  padding-top: 0px;
}

@media screen and (width > 1400px) {
  .dashboard-container {
    width: 100%;
    flex-direction: row-reverse;
  }
  .loading-text-container {
    padding-top: 2em;
  }
}

.show-new-project > .stats-block {
  transition: all 0.15s;
  transform: translateX(50%);
}

.show-new-project > .projectList {
  transform: translateX(-50%);
  transition: all 0.15s;
}

.remove-new-project > .stats-block {
  transform: translateX(-50%);
  transition: all 0.15s;
}

.newProject {
  display: none;
}

.remove-new-project > .projectList {
  transform: translateX(50%);
  transition: all 0.15s;
}

.remove-new-project > .newProject {
  transition: all 0.15s;
  opacity: 0;
  transform: scale(0.8);
}

.stats-block,
.projectList {
  z-index: 1;
}
