.header-logo-image.smaller {
  height: 1.7em;
  width: 1.7em;
  filter: drop-shadow(0px 0px 2px #222);
  cursor: default;
}

.header-logo-image.smaller.hover {
  cursor: pointer;
}
.header-logo-image.smaller.shadow {
  filter: drop-shadow(0px 0px 5px #222);
}
.header-logo-image.smaller.shadow.light {
  filter: drop-shadow(0px 0px 2px #222);
}
.chatbot-message-container {
  height: fit-content;
  width: 100%;
  height: 80%;
  padding-bottom: 60px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.chat-user-name {
  font-weight: bold;
}

.chatbot-input-container {
  transition: 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);

  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  background-color: var(--container-background);
  box-shadow: 0px 0px 3px rgba(255, 255, 255, 0.3);
  border-radius: 14px;
  bottom: 2em;
  height: 7%;
}
.chatbot-input-container.light {
  background-color: var(--container-background);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}
.chatbot-send-icon {
  height: 50%;
  margin-right: 0.6em;
  cursor: pointer;
}
.chatbot-send-icon.dark {
  filter: invert();
}

.chatbot-send-icon:hover {
  transform: scale(1.1);
}

.chatbot-message-container::-webkit-scrollbar {
  width: 7px;
}

.chatbot-message-container::-webkit-scrollbar-track {
  background: transparent;
}

.chatbot-message-container::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
  border-radius: 10px; /* Roundness of the scroll thumb */
  padding-right: 0.2em;
}

.chatbot-message {
  margin: .5em;
  padding: 1em;
  border-radius: 24px;
  width: fit-content;
  word-wrap: break-word;
  color: white;
}

.chatbot-message-user-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(254, 254, 254, 1);
}

.chatbot-chat-input {
  transition: 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);

  width: 100%;
  margin-right: 0.5em;
  height: 100%;
  background-color: var(--container-background);
  border: none;
  outline: none;
  margin-left: 1em;
  color: var(--letter-color);
  font-size: 1em;
  border-radius: 10px 0 0 10px;
}

.chatbot-message.user {
  background-color: var(--message-user-background);
  color: white;
  align-self: flex-end;
}

.chatbot-message.bot {
  background-color: var(--message-bot-background);
  color: white;
}

.container.smaller {
  position: relative;
  height: 20px;
  width: 17.2px;
  transform: scale(0.5);
}
.cube.smaller {
  position: absolute;
  width: 17.2px;
  height: 20px;
}

.face.smaller {
  height: 10px;
  width: 10px;
  position: absolute;
  transform-origin: 0 0;
}
.chat-user-name {
  margin-bottom: 0.5em;
}

code {
  margin-top: 1em;
  background: rgba(0, 0, 0, 0.5);
  color: var(--letter-color);
  border-radius: 10px;
  page-break-inside: avoid;
  font-family: monospace;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--primary-color);
  color: var(--primary-color);
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse.light {
  background-color: rgba(57, 169, 245, 0.6);
  color: rgba(57, 169, 245, 0.6);
}
.dot-pulse.dark {
  background-color: var(--primary-color);
  color: var(--primary-color);
}
.dot-pulse.light::before,
.dot-pulse.light::after {
  background-color: rgba(57, 169, 245, 0.6);
  color: rgba(57, 169, 245, 0.6);
}

.dot-pulse.dark::before,
.dot-pulse.dark::after {
  background-color: var(--primary-color);
  color: var(--primary-color);
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

.dot-pulse-container {
  margin-left: 3em;
  margin-bottom: 1em;
  z-index: 1000;
  padding: 0.2em;
}

.send-to-chat-label {
  font-size: small;
  color: var(--message-info);
  margin-left: 1.5em;
  cursor: pointer;
}

.tags-container {
  height: 7%;
  position: absolute;
  top: 80%;
  gap: 0.2em;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow-x: scroll;
  width: 90%;
}

.tags-container::-webkit-scrollbar {
  display: none;
}

.tags {
  background-color: var(--message-user-background);
  margin: 0.1em;
  padding: 0.2em .4em;
  border-radius: 24px;
  color: white;
  cursor: pointer;
  font-size: medium;
  flex-wrap: wrap;
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.tags:hover {
  filter: brightness(1.1);
}

.chatbot-send-icon.light {
  filter: invert(50%);
}

.message-task-title {
  color: white;
}

.message-task-step-title,
.message-task-step-description {
  color: var(--letter-color);
}

.chatbot-message.bot.errorMessage {
  background-color: rgba(250, 44, 44, 0.271);
  border: 1px solid red;
  border-radius: 10px;
  font-size: smaller;
}
