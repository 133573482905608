.project-page-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.project-info-container {
  /* grid-column: span 2; */
}

.project-standard-container {
  height: 0;
  min-height: 30vh;
  max-width: 100%;
}

.taller {
  height: 85vh;
}

@media only screen and (width > 1200px) {
  .project-page-container {
    flex-direction: row;
    justify-content: center;
  }

  .project-info-container {
    max-width: 1200px;
    width: 90%;
    height: 70vh;
  }
  .project-standard-container {
    height: 70vh;
  }

  .taller {
    height: 70vh;
  }

  .project-info-container {
    height: 70vh;
  }

  .project-standard-container {
    height: 70vh;
    max-width: 100%;
  }
}

.project-tasksTodo-container {
  width: 100%;
  display: flex;
  height: 90%;
  padding-bottom: 3em;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  overflow-y: scroll;
  border-radius: 10px;
}

.project-tasksTodo-container::-webkit-scrollbar {
  width: 7px;
}

.project-tasksTodo-container::-webkit-scrollbar-track {
  background: transparent;
}

.project-tasksTodo-container::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
  border-radius: 10px; /* Roundness of the scroll thumb */
  padding-right: 0.2em;
}
.project-page-menu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  margin-bottom: 2em;
}

.option-label.selected {
  color: var(--opt-selected);
}

.option-label {
  color: var(--opt-deselected);
  font-size: xx-large;
  cursor: pointer;
}

.task-container {
  transition: 0.2s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  padding: 0.8em;
  width: 90%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--subcontainer-background);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  margin-top: 1em;
  cursor: pointer;
  text-decoration: none;
}

.task-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.task-dot.notstarted {
  background-color: rgba(85, 171, 232, 0.747);
}
.task-dot.inprogress {
  background-color: rgba(255, 191, 54, 0.747);
}
.task-dot.finished {
  background-color: rgba(141, 232, 85, 0.747);
}

.task-title {
  width: 80%;
  word-break: break-word;
}
