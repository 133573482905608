.landingPage-container {
  min-height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}
.landingPage-login-container {
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}
.landingImg-container {
  height: 80vh;
  display: flex;
  align-items: center;
  text-align: center;
}
.landingImg {
  height: 25em;
}
.landing-logo-image {
  width: 80%;
  max-width: 600px;
}

.landingPage-google-button {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1em;
}

.landingPage-google-button-image:hover {
  transform: scale(1.1);
}

.landingPage-google-button-image {
  cursor: pointer;
}

.google-text {
  color: var(--letter-color);
  font-size: x-large;
  font-family: "PoppinsLight", sans-serif;
  font-weight: 100;
}

.taskwise-text {
  font-size: xx-large;
}

.landing-page-logo {
  width: 200px;
}

.landingPage-title {
  font-size: 3em;
  font-weight: 600;
}
