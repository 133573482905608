.title-info:hover + .project-info-description-container {
  visibility: hidden;
  background-color: red;
}

.project-info-container {
  height: fit-content;
}

.project-info-description-container::-webkit-scrollbar {
  display: none;
}

.project-info-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.standard-container-title.editable {
  justify-content: space-between;
}
.editable-title {
  height: 1.5em;
  font-family: "PoppinsLight";
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  text-align: center;
  font-size: x-large;
  font-weight: bold;
  width: 100%;
  overflow: visible;
  color: var(--letter-color);
  font-weight: 900;
}
.editable-title:disabled {
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-info-description-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.description-text {
  font-size: medium;
  width: 100%;
  padding: 3%;
  height: 90%;
  background-color: transparent;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  text-align: justify;
  color: var(--letter-color);
  font-family: "PoppinsLight";
  color: gray;
}

.description-text::-webkit-scrollbar {
  width: 7px;
}

.description-text::-webkit-scrollbar-track {
  background: transparent;
}

.description-text::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
  border-radius: 10px; /* Roundness of the scroll thumb */
  padding-right: 0.2em;
}

.description-text:disabled {
  color: var(--letter-color);
}

.project-info-option-icon {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid white;
}

.project-info-btn-container {
  display: flex;
  gap: 1em;
  align-items: center;
  margin-right: 1em;
}

.project-expandable.hidden {
  visibility: hidden;
  transition: all 0.5s;
  height: 0;
  opacity: 0;
}

.project-expandable {
  transition: all 0.5s;
  opacity: 1;
  height: 30vh;
  width: 100%;
}

.project-option-icon {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  transition: all 0.2s;
}

.project-option-icon.plus-minus {
  width: 1.5em;
  height: 1.5em;
  filter: opacity(0.5);
}

.project-option-icon:hover {
  transform: scale(1.1);
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.popup-content {
  display: flex;
  background-color: var(--container-background);
  padding: 20px;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  height: 230px;
  max-width: 500px;
  border-color: var(--popup-border);
}
.popup-content > h1 {
  color: var(--popup-text);
}
.pop-up-text {
  color: var(--popup-text);
  padding: 0.5em;
}

.project-option-icon.dark {
  filter: invert();
}
