.standard-container-button.create {
  right: 0;
  border-radius: 24px 0 24px 0;
  font-weight: 100;
}

.standard-container-button.cancel,
.standard-container-button.create {
  width: 25%;
}

.standard-container-button.new {
  width: 15%;
  font-weight: bold;
  font-size: medium;
}

.standard-container-input {
  border-radius: 24px;
  margin-top: -10px;
  width: 90%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border: none;
  outline: none;
  font-size: medium;
  color: var(--letter-color);
  padding: 4%;
  resize: none;
  height: 15%;
  background-color: var(--subcontainer-background);
}

.standard-container-input::-webkit-scrollbar {
  display: none;
}

.standard-container-input.description {
  height: 25%;
}

.newProject-form {
  width: 90%;
  height: 75%;
  gap: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-project-error {
  color: rgba(255, 0, 0, 0.684);
  margin-top: 8px;
}

.selector-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5%;
  width: 90%;
}

.selector-container input {
  height: 4em;
}

@keyframes error {
  0% {
    background-color: rgba(70, 81, 237, 0.16);
  }
  50% {
    background-color: rgba(70, 81, 237, 0.35);
  }
  100% {
    background-color: rgba(70, 81, 237, 0.16);
  }
}

.error {
  animation: error 1s;
}

.newProject-form {
  z-index: 1;
}