.open-collaborators-button {
  border: none;
  background-color: rgba(0, 0, 0, 0);
  font-size: xx-large;
  cursor: pointer;
}

.collaborators-container {
  display: flex;
  padding-top: 1em;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  width: 100%;
}

.chatbot-message-container.collaborator-chat {
  padding-bottom: 5px;
}

.collaborators-container.new-collaborator {
  padding: 7%;
  box-sizing: border-box;
}

.collaborators-container::-webkit-scrollbar {
  width: 7px;
}

.collaborators-containert::-webkit-scrollbar-track {
  background: transparent;
}

/* Style the thumb of the scrollbar */
.collaborators-container::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
  border-radius: 10px; /* Roundness of the scroll thumb */
  padding-right: 0.2em;
}

.standard-container.project-collaborator-container {
  height: auto;
}

.standard-container.popup-content.send-invitation {
  height: 230px;
  display: flex;
  gap: 1.5em;
}
.sent-succesfully {
  margin-top: 0.5em;
}
.wrong-mail {
  margin-top: 0.5em;
  color: rgba(255, 0, 0, 0.8);
}
.standard-container-input.mail {
  text-align: center;
}
