.loading-contanier {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10em;
  width: 100%;
  height: 100%;
}

.container {
  position: relative;
  height: 75px;
  width: 64.5px;
  transform: scale(0.5);
}
.cube {
  position: absolute;
  width: 64.5px;
  height: 75px;
}
.right1 {
  background: rgba(255, 255, 255, 0.3);
  transform: rotate(-30deg) skewX(-30deg) translate(49px, 65px) scaleY(0.86);
}
.left1 {
  background: rgba(200, 200, 200, 0.3);
  transform: rotate(90deg) skewX(-30deg) scaleY(0.86) translate(25px, -50px);
}
.top.dark {
  background: rgba(13, 109, 194, 0.2);
  transform: rotate(210deg) skew(-30deg) translate(-75px, -22px) scaleY(0.86);
  z-index: 2;
}

.top.light {
  background: rgba(13, 109, 194, 0.3);
  transform: rotate(210deg) skew(-30deg) translate(-75px, -22px) scaleY(0.86);
  z-index: 2;
}

.face {
  height: 37.5px;
  width: 37.5px;
  position: absolute;
  transform-origin: 0 0;
}

@media only screen and (width > 500px) {
  .container {
    height: 100px;
    width: 86px;
  }
  .cube {
    width: 86px;
    height: 100px;
  }
  .face {
    height: 50px;
    width: 50px;
  }
}
.h1.w1.l1 {
  z-index: -1;
  animation-name: h1w1l1;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h1w1l1 {
  0% {
    transform: translate(0%, -100%);
  }
  14% {
    transform: translate(-50%, -125%);
  }
  28% {
    transform: translate(0%, -150%);
  }
  43% {
    transform: translate(0%, -200%);
  }
  57% {
    transform: translate(50%, -175%);
  }
  71% {
    transform: translate(0%, -150%);
  }
  85% {
    transform: translate(0%, -100%);
  }
  100% {
    transform: translate(0%, -100%);
  }
}
.h1.w1.l2 {
  z-index: -1;
  animation-name: h1w1l2;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h1w1l2 {
  0% {
    transform: translate(50%, -75%);
  }
  14% {
    transform: translate(50%, -75%);
  }
  28% {
    transform: translate(100%, -100%);
  }
  43% {
    transform: translate(100%, -150%);
  }
  57% {
    transform: translate(100%, -150%);
  }
  71% {
    transform: translate(50%, -125%);
  }
  85% {
    transform: translate(50%, -75%);
  }
  100% {
    transform: translate(50%, -75%);
  }
}
.h1.w1.l3 {
  z-index: -1;
  animation-name: h1w1l3;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h1w1l3 {
  0% {
    transform: translate(100%, -50%);
  }
  14% {
    transform: translate(150%, -25%);
  }
  28% {
    transform: translate(200%, -50%);
  }
  43% {
    transform: translate(200%, -100%);
  }
  57% {
    transform: translate(150%, -125%);
  }
  71% {
    transform: translate(100%, -100%);
  }
  85% {
    transform: translate(100%, -50%);
  }
  100% {
    transform: translate(100%, -50%);
  }
}
.h1.w2.l1 {
  z-index: -1;
  animation-name: h1w2l1;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h1w2l1 {
  0% {
    transform: translate(-50%, -75%);
  }
  14% {
    transform: translate(-100%, -100%);
  }
  28% {
    transform: translate(-100%, -100%);
  }
  43% {
    transform: translate(-100%, -150%);
  }
  57% {
    transform: translate(-50%, -125%);
  }
  71% {
    transform: translate(-50%, -125%);
  }
  85% {
    transform: translate(-50%, -75%);
  }
  100% {
    transform: translate(-50%, -75%);
  }
}
.h1.w2.l2 {
  z-index: -1;
  animation-name: h1w2l2;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h1w2l2 {
  0% {
    transform: translate(0%, -50%);
  }
  14% {
    transform: translate(0%, -50%);
  }
  28% {
    transform: translate(0%, -50%);
  }
  43% {
    transform: translate(0%, -100%);
  }
  57% {
    transform: translate(0%, -100%);
  }
  71% {
    transform: translate(0%, -100%);
  }
  85% {
    transform: translate(0%, -50%);
  }
  100% {
    transform: translate(0%, -50%);
  }
}
.h1.w2.l3 {
  z-index: -1;
  animation-name: h1w2l3;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h1w2l3 {
  0% {
    transform: translate(50%, -25%);
  }
  14% {
    transform: translate(100%, 0%);
  }
  28% {
    transform: translate(100%, 0%);
  }
  43% {
    transform: translate(100%, -50%);
  }
  57% {
    transform: translate(50%, -75%);
  }
  71% {
    transform: translate(50%, -75%);
  }
  85% {
    transform: translate(50%, -25%);
  }
  100% {
    transform: translate(50%, -25%);
  }
}
.h1.w3.l1 {
  z-index: -1;
  animation-name: h1w3l1;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h1w3l1 {
  0% {
    transform: translate(-100%, -50%);
  }
  14% {
    transform: translate(-150%, -75%);
  }
  28% {
    transform: translate(-200%, -50%);
  }
  43% {
    transform: translate(-200%, -100%);
  }
  57% {
    transform: translate(-150%, -75%);
  }
  71% {
    transform: translate(-100%, -100%);
  }
  85% {
    transform: translate(-100%, -50%);
  }
  100% {
    transform: translate(-100%, -50%);
  }
}
.h1.w3.l2 {
  z-index: -1;
  animation-name: h1w3l2;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h1w3l2 {
  0% {
    transform: translate(-50%, -25%);
  }
  14% {
    transform: translate(-50%, -25%);
  }
  28% {
    transform: translate(-100%, 0%);
  }
  43% {
    transform: translate(-100%, -50%);
  }
  57% {
    transform: translate(-100%, -50%);
  }
  71% {
    transform: translate(-50%, -75%);
  }
  85% {
    transform: translate(-50%, -25%);
  }
  100% {
    transform: translate(-50%, -25%);
  }
}
.h1.w3.l3 {
  z-index: -1;
  animation-name: h1w3l3;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h1w3l3 {
  0% {
    transform: translate(0%, 0%);
  }
  14% {
    transform: translate(50%, 25%);
  }
  28% {
    transform: translate(0%, 50%);
  }
  43% {
    transform: translate(0%, 0%);
  }
  57% {
    transform: translate(-50%, -25%);
  }
  71% {
    transform: translate(0%, -50%);
  }
  85% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
.h2.w1.l1 {
  z-index: -2;
  animation-name: h2w1l1;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h2w1l1 {
  0% {
    transform: translate(0%, -50%);
  }
  14% {
    transform: translate(-50%, -75%);
  }
  28% {
    transform: translate(0%, -100%);
  }
  43% {
    transform: translate(0%, -100%);
  }
  57% {
    transform: translate(50%, -75%);
  }
  71% {
    transform: translate(0%, -50%);
  }
  85% {
    transform: translate(0%, -50%);
  }
  100% {
    transform: translate(0%, -50%);
  }
}
.h2.w1.l2 {
  z-index: -2;
  animation-name: h2w1l2;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h2w1l2 {
  0% {
    transform: translate(50%, -25%);
  }
  14% {
    transform: translate(50%, -25%);
  }
  28% {
    transform: translate(100%, -50%);
  }
  43% {
    transform: translate(100%, -50%);
  }
  57% {
    transform: translate(100%, -50%);
  }
  71% {
    transform: translate(50%, -25%);
  }
  85% {
    transform: translate(50%, -25%);
  }
  100% {
    transform: translate(50%, -25%);
  }
}
.h2.w1.l3 {
  z-index: -2;
  animation-name: h2w1l3;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h2w1l3 {
  0% {
    transform: translate(100%, 0%);
  }
  14% {
    transform: translate(150%, 25%);
  }
  28% {
    transform: translate(200%, 0%);
  }
  43% {
    transform: translate(200%, 0%);
  }
  57% {
    transform: translate(150%, -25%);
  }
  71% {
    transform: translate(100%, 0%);
  }
  85% {
    transform: translate(100%, 0%);
  }
  100% {
    transform: translate(100%, 0%);
  }
}
.h2.w2.l1 {
  z-index: -2;
  animation-name: h2w2l1;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h2w2l1 {
  0% {
    transform: translate(-50%, -25%);
  }
  14% {
    transform: translate(-100%, -50%);
  }
  28% {
    transform: translate(-100%, -50%);
  }
  43% {
    transform: translate(-100%, -50%);
  }
  57% {
    transform: translate(-50%, -25%);
  }
  71% {
    transform: translate(-50%, -25%);
  }
  85% {
    transform: translate(-50%, -25%);
  }
  100% {
    transform: translate(-50%, -25%);
  }
}
.h2.w2.l2 {
  z-index: -2;
  animation-name: h2w2l2;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h2w2l2 {
  0% {
    transform: translate(0%, 0%);
  }
  14% {
    transform: translate(0%, 0%);
  }
  28% {
    transform: translate(0%, 0%);
  }
  43% {
    transform: translate(0%, 0%);
  }
  57% {
    transform: translate(0%, 0%);
  }
  71% {
    transform: translate(0%, 0%);
  }
  85% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
.h2.w2.l3 {
  z-index: -2;
  animation-name: h2w2l3;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h2w2l3 {
  0% {
    transform: translate(50%, 25%);
  }
  14% {
    transform: translate(100%, 50%);
  }
  28% {
    transform: translate(100%, 50%);
  }
  43% {
    transform: translate(100%, 50%);
  }
  57% {
    transform: translate(50%, 25%);
  }
  71% {
    transform: translate(50%, 25%);
  }
  85% {
    transform: translate(50%, 25%);
  }
  100% {
    transform: translate(50%, 25%);
  }
}
.h2.w3.l1 {
  z-index: -2;
  animation-name: h2w3l1;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h2w3l1 {
  0% {
    transform: translate(-100%, 0%);
  }
  14% {
    transform: translate(-150%, -25%);
  }
  28% {
    transform: translate(-200%, 0%);
  }
  43% {
    transform: translate(-200%, 0%);
  }
  57% {
    transform: translate(-150%, 25%);
  }
  71% {
    transform: translate(-100%, 0%);
  }
  85% {
    transform: translate(-100%, 0%);
  }
  100% {
    transform: translate(-100%, 0%);
  }
}
.h2.w3.l2 {
  z-index: -2;
  animation-name: h2w3l2;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h2w3l2 {
  0% {
    transform: translate(-50%, 25%);
  }
  14% {
    transform: translate(-50%, 25%);
  }
  28% {
    transform: translate(-100%, 50%);
  }
  43% {
    transform: translate(-100%, 50%);
  }
  57% {
    transform: translate(-100%, 50%);
  }
  71% {
    transform: translate(-50%, 25%);
  }
  85% {
    transform: translate(-50%, 25%);
  }
  100% {
    transform: translate(-50%, 25%);
  }
}
.h2.w3.l3 {
  z-index: -2;
  animation-name: h2w3l3;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h2w3l3 {
  0% {
    transform: translate(0%, 50%);
  }
  14% {
    transform: translate(50%, 75%);
  }
  28% {
    transform: translate(0%, 100%);
  }
  43% {
    transform: translate(0%, 100%);
  }
  57% {
    transform: translate(-50%, 75%);
  }
  71% {
    transform: translate(0%, 50%);
  }
  85% {
    transform: translate(0%, 50%);
  }
  100% {
    transform: translate(0%, 50%);
  }
}
.h3.w1.l1 {
  z-index: -3;
  animation-name: h3w1l1;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h3w1l1 {
  0% {
    transform: translate(0%, 0%);
  }
  14% {
    transform: translate(-50%, -25%);
  }
  28% {
    transform: translate(0%, -50%);
  }
  43% {
    transform: translate(0%, 0%);
  }
  57% {
    transform: translate(50%, 25%);
  }
  71% {
    transform: translate(0%, 50%);
  }
  85% {
    transform: translate(0%, 0%);
  }
  100% {
    transform: translate(0%, 0%);
  }
}
.h3.w1.l2 {
  z-index: -3;
  animation-name: h3w1l2;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h3w1l2 {
  0% {
    transform: translate(50%, 25%);
  }
  14% {
    transform: translate(50%, 25%);
  }
  28% {
    transform: translate(100%, 0%);
  }
  43% {
    transform: translate(100%, 50%);
  }
  57% {
    transform: translate(100%, 50%);
  }
  71% {
    transform: translate(50%, 75%);
  }
  85% {
    transform: translate(50%, 25%);
  }
  100% {
    transform: translate(50%, 25%);
  }
}
.h3.w1.l3 {
  z-index: -3;
  animation-name: h3w1l3;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h3w1l3 {
  0% {
    transform: translate(100%, 50%);
  }
  14% {
    transform: translate(150%, 75%);
  }
  28% {
    transform: translate(200%, 50%);
  }
  43% {
    transform: translate(200%, 100%);
  }
  57% {
    transform: translate(150%, 75%);
  }
  71% {
    transform: translate(100%, 100%);
  }
  85% {
    transform: translate(100%, 50%);
  }
  100% {
    transform: translate(100%, 50%);
  }
}
.h3.w2.l1 {
  z-index: -3;
  animation-name: h3w2l1;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h3w2l1 {
  0% {
    transform: translate(-50%, 25%);
  }
  14% {
    transform: translate(-100%, 0%);
  }
  28% {
    transform: translate(-100%, 0%);
  }
  43% {
    transform: translate(-100%, 50%);
  }
  57% {
    transform: translate(-50%, 75%);
  }
  71% {
    transform: translate(-50%, 75%);
  }
  85% {
    transform: translate(-50%, 25%);
  }
  100% {
    transform: translate(-50%, 25%);
  }
}
.h3.w2.l2 {
  z-index: -3;
  animation-name: h3w2l2;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h3w2l2 {
  0% {
    transform: translate(0%, 50%);
  }
  14% {
    transform: translate(0%, 50%);
  }
  28% {
    transform: translate(0%, 50%);
  }
  43% {
    transform: translate(0%, 100%);
  }
  57% {
    transform: translate(0%, 100%);
  }
  71% {
    transform: translate(0%, 100%);
  }
  85% {
    transform: translate(0%, 50%);
  }
  100% {
    transform: translate(0%, 50%);
  }
}
.h3.w2.l3 {
  z-index: -3;
  animation-name: h3w2l3;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h3w2l3 {
  0% {
    transform: translate(50%, 75%);
  }
  14% {
    transform: translate(100%, 100%);
  }
  28% {
    transform: translate(100%, 100%);
  }
  43% {
    transform: translate(100%, 150%);
  }
  57% {
    transform: translate(50%, 125%);
  }
  71% {
    transform: translate(50%, 125%);
  }
  85% {
    transform: translate(50%, 75%);
  }
  100% {
    transform: translate(50%, 75%);
  }
}
.h3.w3.l1 {
  z-index: -3;
  animation-name: h3w3l1;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h3w3l1 {
  0% {
    transform: translate(-100%, 50%);
  }
  14% {
    transform: translate(-150%, 25%);
  }
  28% {
    transform: translate(-200%, 50%);
  }
  43% {
    transform: translate(-200%, 100%);
  }
  57% {
    transform: translate(-150%, 125%);
  }
  71% {
    transform: translate(-100%, 100%);
  }
  85% {
    transform: translate(-100%, 50%);
  }
  100% {
    transform: translate(-100%, 50%);
  }
}
.h3.w3.l2 {
  z-index: -3;
  animation-name: h3w3l2;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h3w3l2 {
  0% {
    transform: translate(-50%, 75%);
  }
  14% {
    transform: translate(-50%, 75%);
  }
  28% {
    transform: translate(-100%, 100%);
  }
  43% {
    transform: translate(-100%, 150%);
  }
  57% {
    transform: translate(-100%, 150%);
  }
  71% {
    transform: translate(-50%, 125%);
  }
  85% {
    transform: translate(-50%, 75%);
  }
  100% {
    transform: translate(-50%, 75%);
  }
}
.h3.w3.l3 {
  z-index: -3;
  animation-name: h3w3l3;
  animation-timing-function: ease;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}
@keyframes h3w3l3 {
  0% {
    transform: translate(0%, 100%);
  }
  14% {
    transform: translate(50%, 125%);
  }
  28% {
    transform: translate(0%, 150%);
  }
  43% {
    transform: translate(0%, 200%);
  }
  57% {
    transform: translate(-50%, 175%);
  }
  71% {
    transform: translate(0%, 150%);
  }
  85% {
    transform: translate(0%, 100%);
  }
  100% {
    transform: translate(0%, 100%);
  }
}
